import { useEffect, useState } from 'react'

import { useLastData } from '@/hooks/useLastData'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'

import { Box, Flex, Skeleton, useDisclosure } from '@chakra-ui/react'

import { AppLayout } from '@/components/layouts/AppLayout'
import { CreateDropdown } from '@/components/layouts/CreateDropdown'
import { EditModeFrame } from '@/components/layouts/EditMode'
import { Head } from '@/components/layouts/Head'
import { Header } from '@/components/layouts/Header'
import { PageSpinnerToast } from '@/components/ui'
import { PreloadCannedVideos } from '@/features/canned-video'
import { EditFacilityModal } from '@/features/facility-crud'
import { GlobalMap } from '@/features/global-map'
import {
  useIncidentsByMarketFacilityQuery,
  useOrganizationQuery,
} from '@/graphql/generated/hooks'
import { mixpanel } from '@/utils/analytics'
import { requireAuth } from '@/utils/auth/requireAuth'

const Global = () => {
  const [facilityId, setFacilityId] = useState(null)
  const {
    isOpen: isEditFacilityOpen,
    onOpen: onEditFacilityOpen,
    onClose: onEditFacilityClose,
  } = useDisclosure()

  const { data, loading: isLoading } = useOrganizationQuery({
    fetchPolicy: 'cache-and-network',
  })
  const { data: marketFacilityIncidents } = useIncidentsByMarketFacilityQuery({
    fetchPolicy: 'network-only',
    pollInterval: 5000,
  })
  const lastData = useLastData(data)

  useEffect(() => {
    isLoading
      ? mixpanel.time_event('Page Load: Global Map View')
      : mixpanel.track('Page Load: Global Map View', {
          number_of_incidents: lastData?.facilities?.edges
            ?.map((e) => e.node?.incidents?.totalCount)
            .reduce((a, b) => a + b, 0),
          number_of_facilities: lastData?.facilities?.edges?.length,
        })
  }, [isLoading])

  const openEditFacility = (id: string, e: MouseEvent) => {
    e.stopPropagation()
    setFacilityId(id)
    onEditFacilityOpen()
  }

  return (
    <>
      <Head metaSubTitle='Global' />
      <PreloadCannedVideos />
      <AppLayout>
        <Box>
          <Header
            dataTestId='globalPage_header'
            left={
              <Flex alignItems='center' paddingLeft='6'>
                <Box paddingRight='3'>
                  <HiOutlineOfficeBuilding color='#000' size={24} />
                </Box>
                {lastData ? (
                  <Box fontSize='20px' fontWeight='extrabold'>
                    {lastData?.currentOrganization?.name}
                  </Box>
                ) : (
                  <Skeleton h='24px' w='90px' />
                )}
              </Flex>
            }
            right={<CreateDropdown />}
          />
          <Box h='calc(100vh - 65px)' pos='relative' w='full'>
            <EditModeFrame />
            <PageSpinnerToast loading={isLoading} />
            <GlobalMap
              facilitiesData={data}
              marketFacilityIncidentsData={marketFacilityIncidents}
              openEditFacility={openEditFacility}
            />
          </Box>
        </Box>
      </AppLayout>

      {isEditFacilityOpen && (
        <EditFacilityModal
          facilityId={facilityId}
          isOpen={isEditFacilityOpen}
          onClose={() => {
            setFacilityId(null)
            onEditFacilityClose()
          }}
        />
      )}
    </>
  )
}

// Default export is needed for NextJS /pages/*
// eslint-disable-next-line import/no-default-export
export default requireAuth(Global)
